import React from 'react';
import { sanitize } from 'utils/domHelpers';

import * as S from './styles';

type MarkdownContentProps = {
  body: string;
  className?: string;
};

export const MarkdownContent: React.FC<MarkdownContentProps> = ({
  body,
  className,
}) => (
  <S.MarkdownText
    className={className}
    dangerouslySetInnerHTML={{
      __html: sanitize(body),
    }}
  />
);

import { mediaQueries, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const MarkdownText = styled.div`
  ${typography.bodyTextSmall}

  ${mediaQueries.forTabletHorizontalUp} {
    ${typography.bodyText}
  }

  ol,
  ul {
    padding-left: ${size.lg}px;
  }
  li {
    list-style-type: decimal;
  }

  p {
    margin-bottom: ${size.md}px;
  }
`;
